<template>
    <FormWrapper :is-loading="isLoading">
      <PageTitle title="Monthly Attendance Export" slot="title" link="/helpContent/Monthly Attendance Export" />
      <ValidationObserver ref="validator" >
          <FormRow>
              <div class="col-sm-2" >
                <Year label="Year" v-model="filtering.selectedYear" />
              </div>
              <div class="col-sm-2" >
                  <InputMonth label="Month" v-model="filtering.selectedMonth"/>
              </div>
              <div class="col-sm-3" >
          <FormButton style="margin-top: 5px" type="primary"  :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
          </FormRow>
      </ValidationObserver>
  </FormWrapper>
</template>

<script>
  import FormWrapper from 'Components/form/FormWrapper';
  import PageTitle from 'Components/layout/PageTitle';
  import loadingMixin from 'Mixins/loadingMixin'
  import Year from "../../components/Year";
  import { ValidationObserver } from 'vee-validate'
  export default {
      name: "MonthlyAttendanceExport",
      mixins: [loadingMixin],
      components: {
          FormWrapper,
          PageTitle,
          Year,
          ValidationObserver
      },
      data() {
          return { 
            filtering: {
                selectedYear:new Date().getFullYear(),
                monthSelected:new Date().getMonth() + 1,
            },
          }
      },
      computed: {

      },
      watch: {
        
      },
      created() {

      },
      methods: {

      }
  }
</script>

<style lang="scss">
.popupRow {
    padding: 5px 0 15px;
}

.pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
}

.paginationText {
    margin: 0 20px;
}

.paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color .2s ease-in-out, color .2s ease-in-out, box-shadow 200ms ease
}

.paginationButton:hover {
    background: #57a3f3;
    color: #fff;

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
    }
}
</style>

